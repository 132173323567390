import dictionaryStyles from "@scenes/admin/components/DictionaryStyles.module.scss";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import React, {useState} from "react";
import {AirlineDictionaryItemDto} from "@models/airlines";
import FilesService from "@services/FilesService";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import styles from "./AirlineTrItem.module.scss";

type Props = {
    model: AirlineDictionaryItemDto;
    onClickUploadLogoImage: (airline: AirlineDictionaryItemDto) => void;
    onChangeFwbVersion: (fwbVersion: number) => void;
};

const AirlineTrItem = ({model, onClickUploadLogoImage, onChangeFwbVersion}: Props) => {

    const {t} = useTranslation();

    const [isDropdownOpen, toggleDropdown] = useState(false);

    const filesService = new FilesService();

    const image = model.logoId ?
        <img
            className={styles.image}
            src={filesService.getImageUrl(model.logoId)}
        /> : <div className={dictionaryStyles.noImageLabel}>No image</div>;

    return <tr>
        <td key={"img"}>{image}</td>
        <td key={"name"}>{model.name}</td>
        <td key={"codeIata"}>{model.codeIata}</td>
        <td key={"codeIcao"}>{model.codeIcao}</td>
        <td key={"iataPrefix"}>{model.iataPrefix}</td>
        <td key={"codeIso2Country"}>{model.codeIso2Country}</td>
        <td>{model.defaultFwbVersion}</td>
        <td key={"manage"}>

            <div className="dropdown-wrapper">
                <Dropdown
                    isOpen={isDropdownOpen}
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    toggle={(e) => toggleDropdown(!isDropdownOpen)}
                    direction={'left'}
                >
                    <DropdownToggle>
                        <i className="icon-menu"/>
                    </DropdownToggle>
                    <DropdownMenu>

                        <DropdownItem
                            onClick={(e) => {
                                e.preventDefault();
                                onClickUploadLogoImage(model);
                            }}
                        >
                            <i className='menu-icon icon-plus'/> {t('admin.airlines.uploadLogo')}
                        </DropdownItem>

                        <DropdownItem
                            onClick={(e) => {
                                e.preventDefault();
                                onChangeFwbVersion(16);
                            }}
                        >
                            <div className={clsx(styles.menuItem, model.defaultFwbVersion == 16 ? styles.checked : null)}>{t('admin.airlines.fwb16Version')}</div>
                        </DropdownItem>

                        <DropdownItem
                            onClick={(e) => {
                                e.preventDefault();
                                onChangeFwbVersion(17);
                            }}
                        >
                            <div className={clsx(styles.menuItem, model.defaultFwbVersion == 17 ? styles.checked : null)}>{t('admin.airlines.fwb17Version')}</div>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>

            </div>

        </td>
    </tr>;
}

export default AirlineTrItem;