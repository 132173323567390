import React, { useEffect, useMemo, useState } from 'react';
import s from './style.module.scss';
import { CargoDetails, Header, RequestSummary, Stats, TrackAndTraceForm } from '@scenes/request/components';
import { Button, CheckBox, PageHeader } from '@root/components';
import { ReactComponent as SwitchIcon } from '@material-design-icons/svg/round/sync_alt.svg';
import { Field, FieldProps, Formik, FormikProps, isString } from 'formik';
import {
    CargoDimensionDto,
    CreateCustomerRequestQs,
    GetCustomerRequestDto,
    SpecialHandlingCode,
} from '@models/customerRequests/customerRequestModels';
import { useAppDispatch, useAppSelector } from '@root/store';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import AirportSelect from '@scenes/customerRequest/searchForm/components/AirportSelect';
import { getIconSymbol } from '@helpers/CssHelpers';
import nameof from 'ts-nameof.macro';
import { CreateCustomerRequestModel } from '@models/customerRequests/formModels';
import FieldError from '@scenes/customerRequest/searchForm/components/FieldError';
import { LocationDto } from '@models/locations';
import { array, boolean, number, object, string } from 'yup';
import i18next from 'i18next';
import { localDateToUtcDate } from '@helpers/dateHelpers';
import LocalizableDatePicker from '@components/LocalizableDatePicker';
import { getServiceModeState } from '@store/settingsStore';
import { actions, fetchCustomerRequest } from '@store/customerRequests/customerRequestsStore';
import { unwrapResult } from '@reduxjs/toolkit';
import { fetchSpecialHandlingCodes } from '@store/cargoes/specialHandlingCodesStore';
import { fetchNatureOfCargoList } from '@store/cargoes/natureOfCargoStore';

const getCreateModelFromExisting = (r: GetCustomerRequestDto, today: Date): CreateCustomerRequestModel => {
    let date = r.dateStartPlan ? localDateToUtcDate(new Date(r.dateStartPlan)) : null;
    date = !date ? today : today > date ? today : date;
    const _hasCargoDimensions: boolean = r.cargoDimensions?.length > 0;

    return {
        totalWeight: r.totalWeight,
        totalVolume: r.totalVolume,
        totalPlaces: r.totalPlaces,
        fromLocationType: r.departureLocation.type,
        fromCodeIata: r.departureLocation.codeIata,
        toLocationType: r.destinationLocation.type,
        toCodeIata: r.destinationLocation.codeIata,
        searchNearBy: r.searchNearBy,
        dateStartPlan: date.toISOString(),
        cargoDimensions: r.cargoDimensions || [],
        hasCargoDimensions: _hasCargoDimensions,
        specialHandlingCodeIds: r.specialHandlingCodes.map((x) => x.id),
        natureOfCargo: r.natureOfCargo,
        goodsDescription: r.goodsDescription,
        useTotalWeight: !_hasCargoDimensions || r.cargoDimensions?.every((cd) => cd.weight === 0),
        isTurnable: (r.isTurnable === null || r.isTurnable === undefined) ? false : r.isTurnable,
        isCargoStacked: (r.isCargoStacked === null || r.isCargoStacked === undefined) ? true : r.isCargoStacked,
    };
};

const getNewModel = (): CreateCustomerRequestModel => {
    return {
        searchNearBy: false,
        cargoDimensions: [],
        natureOfCargo: undefined,
        goodsDescription: undefined,
        hasCargoDimensions: true,
        useTotalWeight: true,
        specialHandlingCodeIds: [],
        dateStartPlan: null,
        fromCodeIata: undefined,
        toCodeIata: undefined,
        totalPlaces: undefined,
        totalWeight: undefined,
        totalVolume: undefined,
        isTurnable: false,
        isCargoStacked: true,
    };
};

const Request: React.FC = () => {
    const [locationFrom, setLocationFrom] = useState<LocationDto>();
    const [locationTo, setLocationTo] = useState<LocationDto>();
    const [handlingCodes, setHandlingCodes] = useState<SpecialHandlingCode[]>([]);
    const { customerRequest: request } = useAppSelector((x) => x.customerRequests);
    const { specialHandlingCodes } = useAppSelector((x) => x.specialHandlingCodes);
    const { natureOfCargoList } = useAppSelector((x) => x.nateruOfCargo);
    const { startServiceMode, endServiceMode } = useAppSelector(appState => appState.settings);
    const [isServiceModeActive, setIsServiceModeActive] = useState(false);
    const { requestId } = useParams<{ requestId: string }>();
    const today = useMemo(() => localDateToUtcDate(new Date()), []);
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getServiceModeState());
    }, []);

    useEffect(() => {
        const now = new Date();
        const start = startServiceMode != null ? new Date(startServiceMode) : null;
        const end = endServiceMode != null ? new Date(endServiceMode) : null;
        setIsServiceModeActive(start != null && end != null && (start <= now && end >= now));
    }, [startServiceMode, endServiceMode]);

    useEffect(() => {
        dispatch(actions.resetCustomerRequest());

        if (requestId == null) {
            return;
        }

        dispatch(fetchCustomerRequest({ id: requestId })).then((x) => {
            const d = unwrapResult(x);
            setLocationFrom(d.departureLocation);
            setLocationTo(d.destinationLocation);
            setHandlingCodes(d.specialHandlingCodes || []);
        });
    }, [requestId]);

    useEffect(() => {
        if (specialHandlingCodes.length == 0) {
            dispatch(fetchSpecialHandlingCodes());
        }
        if (natureOfCargoList.length == 0) {
            dispatch(fetchNatureOfCargoList());
        }
    }, []);

    const validationSchema = useMemo(
        () =>
            object<CreateCustomerRequestModel>().shape({
                dateStartPlan: string().nullable().test('dateStartPlan', t('validation.required'), value => {
                    return value != null;
                }),
                hasCargoDimensions: boolean(),
                useTotalWeight: boolean(),
                isTurnable: boolean(),
                isCargoStacked: boolean(),
                fromLocationType: number().nullable().required(t('validation.required')),
                fromCodeIata: string().nullable().required(t('validation.required')),
                toLocationType: number().nullable().required(t('validation.required')),
                toCodeIata: string().nullable().required(t('validation.required')),
                specialHandlingCodeIds: array()
                    .required(t('validation.required'))
                    .min(1, t('validation.required'))
                    .of<string>(string().required(t('validation.required'))),
                natureOfCargo: string().nullable().required(t('validation.required')),
                totalPlaces: number()
                    .nullable()
                    .when(
                        nameof.full<CreateCustomerRequestModel>((x) => x.hasCargoDimensions),
                        {
                            is: false,
                            then: schema => schema
                                .required(t('validation.required'))
                                .integer(t('validation.integer'))
                                .positive(t('validation.positive'))
                                .typeError(t('validation.integer')),
                            otherwise: schema => schema.typeError(''),
                        },
                    ),
                totalWeight: number()
                    .nullable()
                    .when(
                        [
                            nameof.full<CreateCustomerRequestModel>((x) => x.hasCargoDimensions),
                            nameof.full<CreateCustomerRequestModel>((x) => x.useTotalWeight),
                        ],
                        {
                            is: (hasCargoDimensions, useTotalWeight) =>
                                hasCargoDimensions === false || (hasCargoDimensions && useTotalWeight),
                            then: schema => schema
                                .required(t('validation.required'))
                                .positive(t('validation.positive'))
                                .transform((v, orig) =>
                                    typeof orig === 'string' ? Number(orig.replace(/,/, '.')) : orig,
                                )
                                .typeError(t('validation.numberType')),
                            otherwise: schema => schema.typeError(''),
                        },
                    ),
                totalVolume: number()
                    .nullable()
                    .when(
                        nameof.full<CreateCustomerRequestModel>((x) => x.hasCargoDimensions),
                        {
                            is: false,
                            then: schema => schema
                                .required(t('validation.required'))
                                .positive(t('validation.positive'))
                                .transform((v, orig) =>
                                    typeof orig === 'string' ? Number(orig.replace(/,/, '.')) : orig,
                                )
                                .typeError(t('validation.numberType')),
                            otherwise: schema => schema.typeError(''),
                        },
                    ),
                cargoDimensions: array<CargoDimensionDto>().when(
                    [
                        nameof.full<CreateCustomerRequestModel>((x) => x.hasCargoDimensions),
                        nameof.full<CreateCustomerRequestModel>((x) => x.useTotalWeight),
                    ],
                    (values, schema) => {
                        return !values[0]
                            ? schema.notRequired()
                            : schema.required('Required').of(
                                object<CargoDimensionDto>().shape({
                                    amount: number()
                                        .nullable()
                                        .positive(t('validation.positive'))
                                        .integer(t('validation.integer'))
                                        .typeError(t('validation.numberType'))
                                        .moreThan(0)
                                        .required(t('validation.required')),
                                    width: number()
                                        .nullable()
                                        .moreThan(0)
                                        .required(t('validation.required'))
                                        .transform((v, orig) =>
                                            typeof orig === 'string' ? Number(orig.replace(/,/, '.')) : orig,
                                        )
                                        .typeError(t('validation.numberType')),
                                    height: number()
                                        .nullable()
                                        .moreThan(0)
                                        .required(t('validation.required'))
                                        .transform((v, orig) =>
                                            typeof orig === 'string' ? Number(orig.replace(/,/, '.')) : orig,
                                        )
                                        .typeError(t('validation.numberType')),
                                    length: number()
                                        .nullable()
                                        .moreThan(0)
                                        .required(t('validation.required'))
                                        .transform((v, orig) =>
                                            typeof orig === 'string' ? Number(orig.replace(/,/, '.')) : orig,
                                        )
                                        .typeError(t('validation.numberType')),
                                    weight: values[1]
                                        ? number().nullable()
                                        : number()
                                            .nullable()
                                            .moreThan(0)
                                            .required(t('validation.required'))
                                            .transform((v, orig) =>
                                                typeof orig === 'string' ? Number(orig.replace(/,/, '.')) : orig,
                                            )
                                            .typeError(t('validation.numberType')),
                                }),
                            );
                    },
                ),
            }),
        [i18next.language],
    );

    const onChangeSpecialHandlingCodes = (formikProps: FormikProps<CreateCustomerRequestModel>, codes: SpecialHandlingCode[]) => {
        setHandlingCodes(codes);
        formikProps.setFieldValue(
            nameof.full<CreateCustomerRequestModel>((x) => x.specialHandlingCodeIds),
            codes.map((x) => x.id),
        );
        formikProps.setFieldTouched(
            nameof.full<CreateCustomerRequestModel>((x) => x.specialHandlingCodeIds),
            true,
        );
    };

    const switchAirports = (formikProps: FormikProps<CreateCustomerRequestModel>): void => {
        const { values } = formikProps;
        const { setFieldValue } = formikProps;

        setFieldValue(
            nameof.full<CreateCustomerRequestModel>((x) => x.fromCodeIata),
            values.toCodeIata,
        );
        setFieldValue(
            nameof.full<CreateCustomerRequestModel>((x) => x.fromLocationType),
            values.toLocationType,
        );

        setFieldValue(
            nameof.full<CreateCustomerRequestModel>((x) => x.toCodeIata),
            values.fromCodeIata,
        );
        setFieldValue(
            nameof.full<CreateCustomerRequestModel>((x) => x.toLocationType),
            values.fromLocationType,
        );

        const lFrom = { ...locationFrom };
        const lTo = { ...locationTo };

        if (locationTo) {
            setLocationFrom(lTo);
        }
        if (locationFrom) {
            setLocationTo(lFrom);
        }
    };


    if (isServiceModeActive) {
        return <h3>{t('request.serviceModeActive')}</h3>;
    }

    return (
        <Formik
            initialValues={request ? getCreateModelFromExisting(request, today) : getNewModel()}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                const model = { ...values };
                model.cargoDimensions = (model.hasCargoDimensions ? model.cargoDimensions : []).map((d) => {
                    return {
                        ...d,
                        amount: isString(d.amount) ? parseInt((d.amount as string)) : d.amount,
                        length: isString(d.length) ? parseFloat((d.length as string).replace(/,/, '.')) : d.length,
                        height: isString(d.height) ? parseFloat((d.height as string).replace(/,/, '.')) : d.height,
                        width: isString(d.width) ? parseFloat((d.width as string).replace(/,/, '.')) : d.width,
                        weight: isString(d.weight) ? parseFloat((d.weight as string).replace(/,/, '.')) : d.weight,
                    };
                });
                const qs = CreateCustomerRequestQs.fromModel(model).toQueryString();
                history.push(`/request/search?${qs}`);
            }}
            enableReinitialize={true}>
            {(formikProps) => {
                return (
                    <div className={s.container}>
                        <div className={s.content}>
                            <div className={s.header}>
                                <div className={s.left}>
                                    <Header />
                                </div>
                                <div className={s.right}>
                                    <Stats />
                                </div>
                            </div>
                            <div className={s.work}>
                                <div className={s.left}>
                                    <div className={s.formCard}>
                                        <PageHeader title={t('requestSearchResults.route')} size='subHeader' />
                                        <div className={s.route}>
                                            <div className={s.routeFirst}>
                                                <AirportSelect
                                                    icon={getIconSymbol('icon-start-way')}
                                                    placeholder={`${t('request.from')} (${t('request.departure')})`}
                                                    hideWithoutTariffs={true}
                                                    onChange={(val) => {
                                                        setLocationFrom(val);
                                                        formikProps.setFieldValue(
                                                            nameof.full<CreateCustomerRequestModel>((x) => x.fromLocationType),
                                                            val?.type,
                                                        );
                                                        formikProps.setFieldValue(
                                                            nameof.full<CreateCustomerRequestModel>((x) => x.fromCodeIata),
                                                            val?.codeIata,
                                                        );
                                                    }}
                                                    value={locationFrom}
                                                    hideWarning
                                                />
                                                <FieldError
                                                    error={
                                                        formikProps.errors[
                                                            nameof.full<CreateCustomerRequestModel>((x) => x.fromCodeIata)
                                                            ] as string
                                                    }
                                                    isTouched={
                                                        formikProps.touched[
                                                            nameof.full<CreateCustomerRequestModel>((x) => x.fromCodeIata)
                                                            ] as boolean
                                                    }
                                                />
                                                <Button
                                                    icon={<SwitchIcon fill='currentColor' />}
                                                    type='text'
                                                    variant='primary'
                                                    onClick={() => switchAirports(formikProps)} />

                                                <div className={s.date}>
                                                    <AirportSelect
                                                        icon={getIconSymbol('icon-end-way')}
                                                        placeholder={`${t('request.to')} (${t('request.destination')})`}
                                                        onChange={(val) => {
                                                            setLocationTo(val);
                                                            formikProps.setFieldValue(
                                                                nameof.full<CreateCustomerRequestModel>((x) => x.toLocationType),
                                                                val?.type,
                                                            );
                                                            formikProps.setFieldValue(
                                                                nameof.full<CreateCustomerRequestModel>((x) => x.toCodeIata),
                                                                val?.codeIata,
                                                            );
                                                        }}
                                                        value={locationTo}
                                                        hideWarning
                                                    />
                                                    <FieldError
                                                        error={formikProps.errors[nameof.full<CreateCustomerRequestModel>((x) => x.toCodeIata)] as string}
                                                        isTouched={formikProps.touched[nameof.full<CreateCustomerRequestModel>((x) => x.toCodeIata)] as boolean}
                                                    />

                                                    <Field name='searchNearBy'>
                                                        {({ field }: FieldProps) => (
                                                            <CheckBox
                                                                checked={field.value}
                                                                label={t('request.nearBy')}
                                                                {...field}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>

                                            </div>
                                            <div className={s.routeSecond}>
                                                <LocalizableDatePicker
                                                    label={t('request.plannedDepartDate')}
                                                    name={nameof<GetCustomerRequestDto>(x => x.dateStartPlan)}
                                                    minDate={today.toISOString()}
                                                    showClearButton={true}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <CargoDetails
                                        formikProps={formikProps}
                                        selectedCodes={handlingCodes}
                                        onChangeCodes={(codes) =>
                                            onChangeSpecialHandlingCodes(formikProps, codes)
                                        }
                                        availableCodes={specialHandlingCodes}
                                        availableNatures={natureOfCargoList}
                                    />
                                </div>
                                <div className={s.right}>
                                    <RequestSummary
                                        formikProps={formikProps}
                                        specialHandlingCodeNames={handlingCodes.map((x) => x.name)}
                                        natureOfCargoNames={
                                            natureOfCargoList
                                                .filter((x) => x.id === formikProps.values.natureOfCargo)
                                                .map((x) => x.name)
                                        }
                                    />
                                    <TrackAndTraceForm />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            }
        </Formik>
    );
};

export default Request;