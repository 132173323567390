import styles from './index.module.scss';
import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '@root/store';
import {
    clearCurrentAirwaybill,
    getAwbById,
    getNeighborOrChildrenAirwaybillsById,
    State as AirwaybillState
} from '@store/airwaybills/airwaybillsStore';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import { Form, Formik, useField, useFormikContext } from 'formik';
import { AirwaybillDto, IOtherCharge, IOtherChargeCode } from '@models/awbs/awbsModels';
import { AddressControl } from '@scenes/customerApplication/awb/components/AddressControl';
import nameof from 'ts-nameof.macro';
import InputField from '@scenes/customerApplication/awb/components/InputField';
import { airwaybillNumberMask, validateAirwaybillNumber } from '@helpers/stringHelpers';
import { Col, Row } from 'reactstrap';
import { LocationSelectField } from '@scenes/customerApplication/awb/components/LocationSelectField';
import { AirlineField } from '@scenes/customerApplication/awb/components/AirlineField';
import { QuantityTable } from '@scenes/customerApplication/awb/components/QuantityTable';
import LocalizableDatePicker from '@components/LocalizableDatePicker';
import { ValidationError } from '@models/shared';
import { object, string } from 'yup';
import { useAddressValidation } from '@scenes/customerApplication/awb/components/validations/addressValidation';
import Loader from '@components/Loader';
import { LocationType } from '@models/locations';
import AddressDataService from '@services/AddressDataService';
import AppModal from '@components/AppModal';
import { localStorageService } from '@services/LocalStorageService';
import { Requirement } from '@scenes/customerApplication/awb/components/airWaybillEditor/requirement';
import { AgentBlock } from '@scenes/customerApplication/awb/components/airWaybillEditor/agentBlock';
import { TableWithForm } from '@scenes/customerApplication/awb/components/TableWithForm';
import OtherChargeCodesService from '@services/OtherChargeCodesService';
import { CheckBox } from '@root/components';
import Tippy from '@tippyjs/react';

interface Props {
    airlineIataPrefix?: string;
    airwaybillId: string;
    disabled?: boolean;
    onSubmit: (data: AirwaybillDto) => Promise<ValidationError[]>;
}

interface RecognizedData {
    senderId?: string;
    sender?: string;
    recipientId?: string;
    recipient?: string;
}

interface IOtherChargeTable {
    chargeCodes: IOtherChargeCode[];
    model: IOtherCharge;
    setValue: (model: IOtherCharge) => void;
    index: number;
}

export const AirWaybillEditor = (props: PropsWithChildren<Props>) => {
    const {t} = useTranslation();
    const {airwaybill, airwaybillDetails} = useSelector<ApplicationState, AirwaybillState>(x => x.airwaybills);
    const [recognizedData, setRecognizedData] = useState<RecognizedData>(null);
    const [storedValues, setStoredValues] = useState<Record<string, string>>(null);
    const [searchChargeCode, setSearchChargeCode] = useState('');
    const [chargeCodes, setChargeCodes] = useState<IOtherChargeCode[]>([]);

    const dispatch = useDispatch();

    function isNewAwb() {
        return props.airwaybillId.startsWith('new-awb-');
    }

    useEffect(() => {
        dispatch(getAwbById(props.airwaybillId));

        if (!isNewAwb())
            dispatch(getNeighborOrChildrenAirwaybillsById(props.airwaybillId));

        return () => {
            dispatch(clearCurrentAirwaybill());
        };
    }, [props.airwaybillId]);

    useEffect(() => {
        let isCurrent = true;
        new OtherChargeCodesService()
            .getOtherChargeCodes(searchChargeCode)
            .then(codes => {
                if (isCurrent) {
                    setChargeCodes(codes.data);
                }
            });

        return () => {
            isCurrent = false;
        };
    }, [searchChargeCode]);

    const fetchAlreadyRecognizedAddresses = useMemo(() => {
        return new Promise<RecognizedData>((resolve, reject) => {
            const fetch = async () => {
                let data: RecognizedData = null;

                if (airwaybill == null || airwaybill.senderId != null && airwaybill.recipientId != null) {
                    return data;
                }

                const addressDataService = new AddressDataService();

                const {data: value} = await addressDataService.getRecognizedAddresses(airwaybill.id);

                if (airwaybill.senderId == null && value.senderId != null) {
                    const {data: sender} = await addressDataService.get(value.senderId);
                    data = {...data, senderId: value.senderId, sender: sender.name};
                }

                if (airwaybill.recipientId == null && value.recipientId != null) {
                    const {data: recipient} = await addressDataService.get(value.recipientId);
                    data = {...data, recipientId: recipient.id, recipient: recipient.name};
                }

                return data;
            };

            return fetch()
                .then(resolve)
                .catch(reject);
        });
    }, [airwaybill]);


    useEffect(() => {
        let isCurrent = true;

        const isExistingAwb = !isNewAwb();
        if (isExistingAwb) {
            fetchAlreadyRecognizedAddresses
                .then(data => {
                    if (isCurrent) {
                        setRecognizedData(data);
                    }
                });
        }

        if (airwaybill != null) {
            setStoredValues(localStorageService.getItem<Record<string, string>>('agentData'));
        }

        return () => {
            isCurrent = false;
        };
    }, [airwaybill]);

    const history = useHistory();
    const openAwb = (id: string) => {
        const awbId = id;
        if (awbId != airwaybill.id) {
            history.push(`/awb/edit/${awbId}`);
        }
    };

    const requiredMsg = t('validation.required');
    const exactLengthMsg = t('validation.exactLength');
    const invalidFormatMsg = t('validation.invalidFormat');

    const numbersOnlyRx = useMemo(() => new RegExp('^-?\\d*(\\.\\d+)?$'), []);
    const senderValidation = useAddressValidation(true);
    const recipientValidation = useAddressValidation(false);

    const isMasterAwb = useMemo(() => airwaybill != null && airwaybill.parentAirwaybillId == null && (airwaybillDetails == null || airwaybillDetails.isMaster), [airwaybill, airwaybillDetails]);

    const commonValidationDef = object<Partial<AirwaybillDto>>()
        .shape({
            airwaybillNumber: string()
                .nullable()
                .required(requiredMsg)
                .test(
                    'airWaybillNumber',
                    t('validation.invalidAwbNumberChecksum'),
                    (x) => !isMasterAwb || validateAirwaybillNumber(x))
                .test(
                    'airWaybillNumber',
                    t('validation.invalidAwbNumberAirlineIata'),
                    (x) => props.airlineIataPrefix === undefined || x?.startsWith(props.airlineIataPrefix) == true),
            airportOfDestinationId: string().nullable().required(requiredMsg),
            senderId: string().nullable().required(requiredMsg),
            recipientId: string().nullable().required(requiredMsg),
            currency: string()
                .nullable()
                .required(requiredMsg)
                .length(3, exactLengthMsg)
                .test('currency', invalidFormatMsg, (x) => !numbersOnlyRx.test(x)),
            chgsCode: string()
                .nullable()
                .required(requiredMsg)
                .length(2, exactLengthMsg)
                .test('chgsCode', invalidFormatMsg, (x) => !numbersOnlyRx.test(x)),
            declaredValueForCarriage: string()
                .nullable()
                .required(requiredMsg)
                .test('declaredValueForCarriage', invalidFormatMsg, (x) => x === 'NVD' || numbersOnlyRx.test(x)),
            declaredValueForCustoms: string()
                .nullable()
                .required(requiredMsg)
                .test('declaredValueForCustoms', invalidFormatMsg, (x) => x === 'NCV' || x == 'NVC' || numbersOnlyRx.test(x)),
            amountOfInsurance: string()
                .nullable()
                .required(requiredMsg)
                .test('amountOfInsurance', invalidFormatMsg, (x) => {
                    return x === 'XXX' || x === 'NIL' || numbersOnlyRx.test(x);
                }),
            cargoQuantity: string().nullable().required(requiredMsg),
            cargoGrossWeight: string().nullable().required(requiredMsg),
            cargoRateCharge: string().nullable().required(requiredMsg),
            cargoTotalForRow: string().nullable().required(requiredMsg),
            executionDateStamp: string().nullable().required(requiredMsg),
            executionCityId: string().nullable().required(requiredMsg),
            cargoChargeableWeight: string().nullable().required(requiredMsg),
            routingDepartureAirportId: string<string, AirwaybillDto>()
                .nullable()
                .test('routingDepartureLocation', requiredMsg,
                    (value, context) => {
                        return value != null || context.parent.routingDepartureCityId != null;
                    }),
            sender: senderValidation.nullable(),
            recipient: recipientValidation.nullable()
        });

    const mawbValidationSchema = object<Partial<AirwaybillDto>>()
        .shape({
            agentName: string().nullable().required(requiredMsg),
            agentCityId: string().nullable().required(requiredMsg),
            agentIataCode: string().nullable(),
            requestedFlightAndDate: string().nullable().required(requiredMsg),
            firstCarrierId: string().nullable().required(requiredMsg),
            executedSign: string().nullable().required(requiredMsg)
        })
        .concat(commonValidationDef);

    const hawbValidationSchema = object<Partial<AirwaybillDto>>().concat(commonValidationDef);

    const RenderOtherChargeTable = (props: IOtherChargeTable) => {
        const ref = useRef<HTMLTableRowElement>();
        const [isAgent, setIsAgent] = useState(false);
        const [field] = useField<IOtherCharge[]>(nameof<AirwaybillDto>(x => x.otherCharges));
        const [showMergeWarning, setShowMergeWarning] = useState(false);

        useEffect(() => {
            setIsAgent(props.model != null && props.model.code.endsWith('A'));
        }, [props.model]);

        useEffect(() => {
            if (ref.current) {
                ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }, [ref]);

        const update = (code: string, isChecked: boolean) => {
            const newCode = code + (isChecked ? 'A' : 'C');
            props.setValue({ ...props.model, code: newCode });
            const chargeWithSameCode = field.value.filter((x, idx) => idx != props.index && x.code == newCode);
            setShowMergeWarning(chargeWithSameCode.length > 0);
        };

        return <div>
            <div className={styles.otherChargeCode}>
                <CheckBox
                    className={styles.agentCheckBox}
                    checked={isAgent}
                    label={t('awb.dueAgent')}
                    onCheck={checked => {
                        setIsAgent(checked);
                        const code = props.model.code.substring(0, 2);
                        update(code, checked);
                    }}
                />
                <table>
                    <thead>
                    <tr>
                        <th>{t('awb.dueCode')}</th>
                        <th>{t('awb.dueAmount')}</th>
                    </tr>
                    </thead>
                </table>
                <div className={styles.otherChargeTable}>
                    <table id={'otherChargesTable'}>
                        <tbody>
                        {props.chargeCodes.map((charge, idx) =>
                            <tr
                                onClick={() => update(charge.code, isAgent)}
                                key={idx}
                                ref={props.model?.code.startsWith(charge.code) ? ref : null}
                                className={props.model?.code.startsWith(charge.code) ? styles.selected : null}>
                                <td>{charge.code}{isAgent ? 'A' : 'C'}</td>
                                <td>{charge.description}</td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
                {showMergeWarning && <div className={styles.mergeWarning}>{t('awb.mergeWarning')}</div>}
            </div>
        </div>;
    };
    const RenderForm = () => {
        const context = useFormikContext();
        const [field] = useField<IOtherCharge[]>(nameof<AirwaybillDto>(x => x.otherCharges));

        return <Form className={'form-standard'}>
            <Row>
                <Col>
                    <AddressControl
                        disabled={props.disabled}
                        title={t('awb.shipper')}
                        idFieldName={nameof<AirwaybillDto>(x => x.senderId)}
                        scannedDataFieldName={nameof<AirwaybillDto>(x => x.scannedSenderData)}
                        lookupFieldName={nameof<AirwaybillDto>(x => x.sender)}
                        showAccountNumber
                    />
                    <AddressControl
                        disabled={props.disabled}
                        title={t('awb.consignee')}
                        idFieldName={nameof<AirwaybillDto>(x => x.recipientId)}
                        scannedDataFieldName={nameof<AirwaybillDto>(x => x.scannedRecipientData)}
                        lookupFieldName={nameof<AirwaybillDto>(x => x.recipient)}
                        showAccountNumber
                        allowEmptyAccountNumberTitle={t('awb.recipientAllowEmptyAccountNumber') }
                    />
                    <Row>
                        <Col><h3>{t('awb.alsoNotify')}</h3></Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputField
                                type={'text'}
                                name={nameof<AirwaybillDto>((x) => x.contactToNotify)}
                                label={t('awb.alsoNotify')}
                            />
                        </Col>
                    </Row>

                    {isMasterAwb && <AgentBlock disabled={props.disabled} storedData={storedValues} />}

                    <div className={styles.formBlock} data-cy={'routingAndFlight'}>
                        <h3>{t('awb.routingAndFlightBookings')}</h3>
                        <Row>
                            <Col data-tippy-content={t('awb.departure')}>
                                <LocationSelectField
                                    disabled={props.disabled}
                                    locationFieldName={nameof<AirwaybillDto>((x) => x.routingDepartureCityId)}
                                    subLocationFieldName={nameof<AirwaybillDto>((x) => x.routingDepartureAirportId)}
                                    isOptionDisabled={location => location.type != LocationType.Airport}
                                />
                            </Col>
                        </Row>
                    </div>
                    {isMasterAwb && (
                        <div className={clsx(styles.formBlock, styles.formBlockDark)} data-cy={'route'}>
                            <h3>{t('awb.route')}</h3>
                            <Row>
                                <Col md={3} data-tippy-content={t('awb.route')} data-cy={'routingToFirstDestinationId'}>
                                    <LocationSelectField
                                        locationType={LocationType.Airport}
                                        locationFieldName={nameof<AirwaybillDto>((x) => x.routingToFirstDestinationId)}
                                        disabled={props.disabled}
                                        placeholder={t('awb.route')}
                                    />
                                </Col>
                                <Col md={9} data-cy={'firstCarrierId'}>
                                    <AirlineField
                                        name={nameof<AirwaybillDto>(x => x.firstCarrierId)}
                                        disabled={props.disabled}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col data-tippy-content={t('awb.route')} data-cy={'routingToSecondDestinationId'}>
                                    <LocationSelectField
                                        locationType={LocationType.Airport}
                                        disabled={props.disabled}
                                        locationFieldName={nameof<AirwaybillDto>(x => x.routingToSecondDestinationId)}
                                        placeholder={t('awb.route')}
                                    />
                                </Col>
                                <Col>
                                    <InputField
                                        disabled={props.disabled}
                                        type={'text'}
                                        name={nameof<AirwaybillDto>((x) => x.routingBySecondCarrier)}
                                        label={t('awb.carrier')}
                                    />
                                </Col>
                                <Col data-tippy-content={t('awb.route')} data-cy={'routingToThirdDestinationId'}>
                                    <LocationSelectField
                                        locationType={LocationType.Airport}
                                        disabled={props.disabled}
                                        locationFieldName={nameof<AirwaybillDto>(x => x.routingToThirdDestinationId)}
                                        placeholder={t('awb.route')}
                                    />
                                </Col>
                                <Col>
                                    <InputField
                                        disabled={props.disabled}
                                        type={'text'}
                                        name={nameof<AirwaybillDto>((x) => x.routingByThirdCarrier)}
                                        label={t('awb.carrier')}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
                    <div className={styles.formBlock} data-cy={'airport'}>
                        <Row>
                            <Col data-tippy-content={t('awb.destinationAirport')} data-cy={'airportOfDestinationId'}>
                                <LocationSelectField
                                    disabled={props.disabled}
                                    locationType={LocationType.Airport}
                                    locationFieldName={nameof<AirwaybillDto>(x => x.airportOfDestinationId)}
                                    placeholder={t('awb.destinationAirport')}
                                />
                            </Col>
                            {isMasterAwb && (
                                <>
                                    <Col md={3}>
                                        <InputField
                                            disabled={props.disabled}
                                            type={'text'}
                                            name={nameof<AirwaybillDto>((x) => x.requestedFlightAndDate)}
                                            label={t('awb.flightDate')}
                                            required
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <InputField
                                            disabled={props.disabled}
                                            type={'text'}
                                            name={nameof<AirwaybillDto>((x) => x.requestedFlightAndDate2)}
                                            label={t('awb.flightDate')}
                                            required
                                        />
                                    </Col>
                                </>
                            )}
                        </Row>

                    </div>
                </Col>
                <Col data-cy={'cargoDetails'}>
                    <h3>{t(isMasterAwb ? 'awb.consignmentDetails' : 'awb.hawbDetails')}</h3>
                    <Row>
                        <Col>
                            <InputField
                                disabled={props.disabled}
                                type={'text'}
                                name={nameof<AirwaybillDto>(x => x.airwaybillNumber)}
                                label={t('awb.awbNumber')}
                                id={'awbNumber'}
                                mask={isMasterAwb ? airwaybillNumberMask : null}
                                required
                            />
                        </Col>
                    </Row>
                    <div className={styles.formBlock} data-cy={'issuer'}>
                        <h3>{t('awb.issuer')}</h3>
                        <Row>
                            <Col>
                                <InputField
                                    disabled={props.disabled}
                                    type={'textarea'}
                                    name={nameof<AirwaybillDto>((x) => x.issuedBy)}
                                    label={t('awb.issuedBy')}
                                />
                            </Col>
                        </Row>
                    </div>

                    <div className={styles.formBlock} data-cy={'accountingInformation'}>
                        <h3>{t('awb.accountingInformation')}</h3>
                        <Row>
                            <Col>
                                <InputField
                                    disabled={props.disabled}
                                    type={'textarea'}
                                    name={nameof<AirwaybillDto>((x) => x.accountingInformation)}
                                    label={t('awb.details')}
                                />
                            </Col>
                        </Row>
                    </div>

                    <div className={styles.formBlock} data-cy={'shipmentReferenceInformation'}>
                        <h3>{t('awb.shipmentReferenceInformation')}</h3>
                        <Row>
                            <Col>
                                <InputField
                                    disabled={props.disabled}
                                    type={'text'}
                                    name={nameof<AirwaybillDto>((x) => x.referenceNumber)}
                                    label={t('awb.referenceNumber')}
                                />
                            </Col>
                        </Row>
                    </div>

                    <div className={styles.formBlock} data-cy={'chargesDeclaration'}>
                        <h3 style={{marginBottom: 10}}>{t('awb.chargesDeclaration')}</h3>

                        <div className="d-flex align-content-stretch">
                            <div className={clsx(styles.formSubColumn)} style={{paddingRight: 30}}>
                                <InputField
                                    disabled={props.disabled}
                                    type={'text'}
                                    name={nameof<AirwaybillDto>((x) => x.currency)}
                                    label={t('awb.currency')}
                                    required
                                />

                                <InputField
                                    disabled={props.disabled}
                                    type={'text'}
                                    name={nameof<AirwaybillDto>((x) => x.declaredValueForCarriage)}
                                    label={t('awb.valueForCarriage')}
                                />

                                <InputField
                                    disabled={props.disabled}
                                    type={'text'}
                                    name={nameof<AirwaybillDto>((x) => x.declaredValueForCustoms)}
                                    label={t('awb.valueForCustoms')}
                                />

                                <InputField
                                    disabled={props.disabled}
                                    type={'text'}
                                    name={nameof<AirwaybillDto>((x) => x.amountOfInsurance)}
                                    label={t('awb.amountOfInsurance')}
                                />
                            </div>

                            <div className={clsx(styles.formSubColumn)}>
                                <div className={clsx(styles.ppBlock, styles.chargesWeightValueBlock)}>
                                    <InputField
                                        disabled={props.disabled}
                                        type={'text'}
                                        name={nameof<AirwaybillDto>((x) => x.chgsCode)}
                                        label={'CHGS'}
                                        required
                                    />

                                    <div className={styles.formBlockDark}>
                                        <Tippy
                                            content={t('awb.weightValue')}
                                            trigger={'mousemove'}
                                            theme={'light'}
                                        ><h3>WT/VAL</h3></Tippy>
                                        <div className="d-flex form-check-inline justify-content-center">
                                            <div className="flex-column pr-3">
                                                <InputField
                                                    disabled={props.disabled}
                                                    type={'radio'}
                                                    name={nameof<AirwaybillDto>((x) => x.isWeightOrValuationChargePrepaid)}
                                                    label={'PPD'}
                                                    checkValue={true}
                                                    labelProps={{className: 'form-check-label'}}
                                                    inputProps={{className: 'form-check-input'}}
                                                    valueConverter={(x) => x == 'true'}
                                                />
                                            </div>

                                            <div className="flex-column">
                                                <InputField
                                                    disabled={props.disabled}
                                                    type={'radio'}
                                                    name={nameof<AirwaybillDto>((x) => x.isWeightOrValuationChargePrepaid)}
                                                    label={'COLL'}
                                                    checkValue={false}
                                                    labelProps={{className: 'form-check-label'}}
                                                    inputProps={{className: 'form-check-input'}}
                                                    valueConverter={(x) => x == 'true'}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.formBlockDark}>
                                        <h3>{t('awb.other')}</h3>
                                        <div className="d-flex form-check-inline justify-content-center">
                                            <div className="flex-column pr-3">
                                                <InputField
                                                    disabled={props.disabled}
                                                    type={'radio'}
                                                    name={nameof<AirwaybillDto>((x) => x.isOtherChargePrepaid)}
                                                    checkValue={true}
                                                    label={'PPD'}
                                                    labelProps={{className: 'form-check-label'}}
                                                    inputProps={{className: 'form-check-input'}}
                                                    valueConverter={(x) => x == 'true'}
                                                />
                                            </div>

                                            <div className="flex-column">
                                                <InputField
                                                    disabled={props.disabled}
                                                    type={'radio'}
                                                    name={nameof<AirwaybillDto>((x) => x.isOtherChargePrepaid)}
                                                    checkValue={false}
                                                    label={'COLL'}
                                                    labelProps={{className: 'form-check-label'}}
                                                    inputProps={{className: 'form-check-input'}}
                                                    valueConverter={(x) => x == 'true'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <div className={clsx(styles.formBlock, styles.ppBlock)} data-cy={'handlingInformation'}>
                        <Requirement disabled={props.disabled} placeholder={t('awb.requirements')} />
                        <div className={styles.amountOf}>
                            <h3>{t('awb.handlingInformation')}</h3>
                            <InputField
                                disabled={props.disabled}
                                type={'text'}
                                name={nameof<AirwaybillDto>((x) => x.sci)}
                                label={'SCI'}/>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className={styles.formBlock} data-cy={'rateDescription'}>
                <h3>{t('awb.rateDescription')}</h3>
                <Row>
                    <Col>
                        <table className="table-app" data-cy={'cargoTable'}>
                            <thead>
                            <tr>
                                <th style={{width: 70}}>{t('awb.pieces')}</th>
                                <th style={{width: 70}}>{t('awb.grossWeight')}</th>
                                <th style={{width: 110}}>{t('awb.kl')}</th>
                                <th style={{width: 70}}>{t('awb.rc')}</th>
                                <th style={{width: 70}}>{t('chargeableWeight')}</th>
                                <th style={{width: 80}}>{t('awb.rateToCharge')}</th>
                                <th style={{ width: 130 }}>{t('awb.total')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <InputField
                                        disabled={props.disabled}
                                        type={'text'}
                                        name={nameof<AirwaybillDto>((x) => x.cargoQuantity)}
                                        label={t('awb.pieces')}
                                        placeholder={''}
                                        required
                                    />
                                </td>
                                <td>
                                    <InputField
                                        disabled={props.disabled}
                                        type={'text'}
                                        name={nameof<AirwaybillDto>((x) => x.cargoGrossWeight)}
                                        label={t('awb.grossWeight')}
                                        placeholder={''}
                                        required
                                    />
                                </td>
                                <td>
                                    <div className="d-flex form-check-inline justify-content-center">
                                        <div className="flex-column pr-3">
                                            <InputField
                                                disabled={props.disabled}
                                                type={'radio'}
                                                name={nameof<AirwaybillDto>((x) => x.isCargoGrossWeightUnitKg)}
                                                label={t('units.kg')}
                                                checkValue={true}
                                                labelProps={{className: 'form-check-label'}}
                                                inputProps={{className: 'form-check-input'}}
                                                valueConverter={(x) => x == 'true'}
                                                required
                                            />
                                        </div>

                                        <div className="flex-column">
                                            <InputField
                                                disabled={props.disabled}
                                                type={'radio'}
                                                name={nameof<AirwaybillDto>((x) => x.isCargoGrossWeightUnitKg)}
                                                label={t('units.lb')}
                                                checkValue={false}
                                                labelProps={{className: 'form-check-label'}}
                                                inputProps={{className: 'form-check-input'}}
                                                valueConverter={(x) => x == 'true'}
                                            />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <InputField
                                        disabled={props.disabled}
                                        type={'text'}
                                        name={nameof<AirwaybillDto>((x) => x.cargoClass)}
                                        label={t('awb.rc')}
                                        placeholder={''}
                                    />
                                </td>
                                <td>
                                    <InputField
                                        disabled={props.disabled}
                                        type={'text'}
                                        name={nameof<AirwaybillDto>((x) => x.cargoChargeableWeight)}
                                        label={t('chargeableWeight')}
                                        placeholder={''}
                                        required
                                    />
                                </td>
                                <td>
                                    <InputField
                                        disabled={props.disabled}
                                        type={'text'}
                                        name={nameof<AirwaybillDto>((x) => x.cargoRateCharge)}
                                        label={t('awb.rateToCharge')}
                                        placeholder={''}
                                        required
                                    />
                                </td>
                                <td>
                                    <InputField
                                        disabled={props.disabled}
                                        type={'text'}
                                        name={nameof<AirwaybillDto>((x) => x.cargoTotalForRow)}
                                        label={t('awb.total')}
                                        placeholder={''}
                                        required
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <QuantityTable disabled={props.disabled} />
                    </Col>
                </Row>
            </div>
            <div className="d-flex flex-column">
                <div className="d-flex">
                    <div className={clsx(styles.flexColumn, styles.flexColumnLeft)}>
                        <div className={styles.formBlock} data-cy={'charges'}>
                            <h3>{t('awb.chargesSummary')}</h3>

                            <div className="d-flex align-content-stretch">
                                <div className={clsx(styles.formSubColumn)} style={{paddingRight: 20}}
                                     data-cy={'prepaid'}>
                                    <div className={styles.formBlockDark}>
                                        <h3>{t('awb.prepaid')}</h3>

                                        <InputField
                                            disabled={props.disabled}
                                            type={'text'}
                                            name={nameof<AirwaybillDto>((x) => x.prepaidWeightCharge)}
                                            label={t('awb.chargeableWeight')}
                                        />

                                        <InputField
                                            disabled={props.disabled}
                                            type={'text'}
                                            name={nameof<AirwaybillDto>((x) => x.prepaidValuationCharge)}
                                            label={t('awb.valuationCharge')}
                                        />

                                        <InputField
                                            disabled={props.disabled}
                                            type={'text'}
                                            name={nameof<AirwaybillDto>((x) => x.prepaidTax)}
                                            label={t('awb.tax')}
                                        />

                                        <InputField
                                            disabled={props.disabled}
                                            type={'text'}
                                            name={nameof<AirwaybillDto>((x) => x.prepaidTotalOtherChargesDueAgent)}
                                            label={t('awb.otherChargesDueAgent')}
                                        />

                                        <InputField
                                            disabled={props.disabled}
                                            type={'text'}
                                            name={nameof<AirwaybillDto>((x) => x.prepaidTotalOtherChargesDueCarrier)}
                                            label={t('awb.otherChargesDueCarrier')}
                                        />

                                        <InputField
                                            disabled={props.disabled}
                                            type={'text'}
                                            name={nameof<AirwaybillDto>((x) => x.prepaidTotal)}
                                            label={t('awb.totalPrepaid')}
                                        />
                                    </div>
                                </div>

                                <div className={clsx(styles.formSubColumn)} data-cy={'collect'}>
                                    <div className={styles.formBlockDark}>
                                        <h3>{t('awb.collect')}</h3>

                                        <InputField
                                            disabled={props.disabled}
                                            type={'text'}
                                            name={nameof<AirwaybillDto>((x) => x.collectWeightCharge)}
                                            label={t('chargeableWeight')}
                                        />

                                        <InputField
                                            disabled={props.disabled}
                                            type={'text'}
                                            name={nameof<AirwaybillDto>((x) => x.collectValuationCharge)}
                                            label={t('awb.valuationCharge')}
                                        />

                                        <InputField
                                            disabled={props.disabled}
                                            type={'text'}
                                            name={nameof<AirwaybillDto>((x) => x.collectTax)}
                                            label={t('awb.tax')}
                                        />

                                        <InputField
                                            disabled={props.disabled}
                                            type={'text'}
                                            name={nameof<AirwaybillDto>((x) => x.collectTotalOtherChargesDueAgent)}
                                            label={t('awb.otherChargesDueAgent')}
                                        />

                                        <InputField
                                            disabled={props.disabled}
                                            type={'text'}
                                            name={nameof<AirwaybillDto>((x) => x.collectTotalOtherChargesDueCarrier)}
                                            label={t('awb.otherChargesDueCarrier')}
                                        />

                                        <InputField
                                            disabled={props.disabled}
                                            type={'text'}
                                            name={nameof<AirwaybillDto>((x) => x.collectTotal)}
                                            label={t('awb.totalCollect')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={clsx(styles.flexColumn)}>
                        <div className={styles.formBlock} data-cy={'otherCharges'}>
                            <h3>{t('awb.otherCharges')}</h3>

                            <Row>
                                <Col>
                                    <TableWithForm
                                        disabled={props.disabled}
                                        formTitle={t('awb.otherCharges')}
                                        fieldName={nameof<AirwaybillDto>((x) => x.otherCharges)}
                                        onFormShow={() => {
                                            const selectedRow = document.querySelector('#otherChargesTable tr.selected');
                                            if (selectedRow == null) {
                                                return;
                                            }

                                            selectedRow.scrollIntoView();
                                        }}
                                        columns={[
                                            {
                                                title: t('awb.dueCode'),
                                                hideTitleInForm: true,
                                                getValue: (x) => x.code,
                                                formField: (editedModel, setValue, index) => <RenderOtherChargeTable
                                                    chargeCodes={chargeCodes}
                                                    model={editedModel}
                                                    setValue={setValue}
                                                    index={index}
                                                />
                                            },
                                            {
                                                title: t('awb.dueAmount'),
                                                getValue: (x) => x.amount.toString(),
                                                formField: (editedModel, setValue) => <div>
                                                    <input
                                                        type={'number'}
                                                        className={'form-control'}
                                                        value={editedModel?.amount || 0}
                                                        onChange={(e) => {
                                                            setValue({
                                                                ...editedModel,
                                                                amount: e.currentTarget.valueAsNumber
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            }
                                        ]}
                                        emptyModel={({ code: '', amount: 0 })}
                                        onDelete={valueIndex => {
                                            if (valueIndex != null) {
                                                context.setFieldValue(nameof<AirwaybillDto>((x) => x.otherCharges),
                                                    field.value.filter((x, idx) => idx !== valueIndex));
                                            }

                                        }}
                                        onSave={(selectedIndex, value) => {
                                            let values = (field.value || []);
                                            if (selectedIndex < 0) {
                                                values = [...values, value];
                                            } else {

                                                values = values
                                                    .map((x, idx) => idx == selectedIndex ? value : x);
                                            }

                                            let totalDueAgent = 0;
                                            let totalDueCarrier = 0;
                                            const dict = values
                                                .reduce((record, item) => {
                                                    if(item.code.endsWith('A')) {
                                                        totalDueAgent += item.amount;
                                                    } else {
                                                        totalDueCarrier += item.amount;
                                                    }
                                                    record[item.code] = (record[item.code] || 0) + item.amount;
                                                    return record;
                                                }, {} as Record<string, number>);

                                            values = Object.keys(dict)
                                                .map(key => ({ code: key, amount: dict[key] }));

                                            context.setFieldValue(nameof<AirwaybillDto>(x => x.otherCharges), values);
                                            context.setFieldValue(nameof<AirwaybillDto>((x) => x.collectTotalOtherChargesDueAgent), totalDueAgent.toString());
                                            context.setFieldValue(nameof<AirwaybillDto>((x) => x.collectTotalOtherChargesDueCarrier), totalDueCarrier.toString());
                                            context.setFieldValue(nameof<AirwaybillDto>((x) => x.collectTotal), (totalDueAgent + totalDueCarrier).toString());
                                        }} />
                                </Col>
                            </Row>
                        </div>

                        <div className={styles.formBlock} data-cy={'carriersExecution'}>
                            <h3>{t('awb.carriersExecution')}</h3>
                            <Row>
                                <Col data-tippy-content={t('awb.date')} data-cy={'executionDateStamp'}>
                                    <LocalizableDatePicker
                                        name={nameof<AirwaybillDto>((x) => x.executionDateStamp)}
                                        disabled={props.disabled}
                                        showClearButton={true}
                                    />
                                </Col>
                                <Col data-tippy-content={t('awb.place')}>
                                    <LocationSelectField
                                        disabled={props.disabled}
                                        locationFieldName={nameof<AirwaybillDto>((x) => x.executionCityId)}
                                        placeholder={t('awb.place')}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <InputField
                                        disabled={props.disabled}
                                        type={'text'}
                                        name={nameof<AirwaybillDto>((x) => x.executedSign)}
                                        required={isMasterAwb}
                                        label={t('awb.issuingCarriersAgent')}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            {props.children}
        </Form>;
    };

    const RecognizeSuggestionsForm = () => {
        const context = useFormikContext();

        return recognizedData && <AppModal
            isOpen={true}
            onClickCloseButton={() => setRecognizedData(null)}
            body={
                <>
                    <div>{t('awb.findAddress')}</div>
                    {recognizedData.sender && <Row>
                        <Col>
                            <div>{t('awb.shipper')}</div>
                            <div>{recognizedData.sender}</div>
                        </Col>
                    </Row>}
                    {recognizedData.recipient && <Row>
                        <Col>
                            <div>{t('awb.consignee')}</div>
                            <div>{recognizedData.recipient}</div>
                        </Col>
                    </Row>}
                    <Row>
                        <Col>
                            {t('awb.confirmAddressReplacing')}
                        </Col>
                    </Row>
                </>
            }
            footer={<>
                <button
                    className={'btn btn-primary d-block float-left px-4'}
                    onClick={(e) => {
                        e.preventDefault();
                        const updatedField: { key: string, value: string }[] = [];

                        if (recognizedData.senderId != null) {
                            updatedField.push({
                                key: nameof<AirwaybillDto>(x => x.senderId),
                                value: recognizedData.senderId
                            });
                        }

                        if (recognizedData.recipientId != null) {
                            updatedField.push({
                                key: nameof<AirwaybillDto>(x => x.recipientId),
                                value: recognizedData.recipientId
                            });
                        }

                        for (const updatedFieldElement of updatedField) {
                            context.setFieldValue(updatedFieldElement.key, updatedFieldElement.value)
                                .then(() => {
                                    context.validateField(updatedFieldElement.key);
                                });
                        }

                        setRecognizedData(null);
                    }}
                >
                    {t('awb.replace')}
                </button>
                <button
                    className={'btn btn-primary d-block float-left px-4'}
                    onClick={(e) => {
                        e.preventDefault();
                        setRecognizedData(null);
                    }}>
                    {t('cancel')}
                </button>
            </>} />;
    };

    if (airwaybill == null) {
        return <Loader />;
    }

    return <>
        {!isNewAwb() && <div className={styles.awbButtons}>
            <ul className={styles.awbTabs}>
                <li className={clsx(styles.tab, isMasterAwb ? styles.active : null)}>
                    <a onClick={() => openAwb(airwaybillDetails?.parentId || airwaybill?.id)}>{t('awb.awb')}</a>
                </li>
                {airwaybillDetails?.neighborOrChildAirwaybills.map((id, idx) => <li
                    key={idx}
                    className={clsx(styles.tab, airwaybill?.id == id ? styles.active : null)}>
                    <a onClick={() => openAwb(id)}>{t('customerApplicationEventLog.houseAirwaybill')} {idx + 1}</a>
                </li>)}
            </ul>
        </div>}

        <Formik
            enableReinitialize
            validationSchema={isMasterAwb ? mawbValidationSchema : hawbValidationSchema}
            initialValues={airwaybill}
            onSubmit={async values => {
                // TODO: refactor

                const validationErrors = [];

                const serverValidationErrors = await props.onSubmit(values);
                if (serverValidationErrors && serverValidationErrors.length > 0) {
                    validationErrors.push(...serverValidationErrors);
                }

                document.querySelectorAll('.validationMessage').forEach((x) => x.remove());

                if (validationErrors.length == 0) {
                    localStorageService.setItem('agentData', {
                        [nameof<AirwaybillDto>((x) => x.agentName)]: values.agentName,
                        [nameof<AirwaybillDto>((x) => x.agentCityId)]: values.agentCityId,
                        [nameof<AirwaybillDto>((x) => x.agentIataCode)]: values.agentIataCode,
                        [nameof<AirwaybillDto>((x) => x.issuingCarrierAgentAccountNumber)]: values.issuingCarrierAgentAccountNumber
                    });
                    return;
                }

                validationErrors.forEach((f) => {
                    let els: HTMLElement[] = Array.from(document.getElementsByName(f.name));
                    if (els.length == 0) {
                        els = Array.from(document.getElementsByClassName(`.name-${f.name}`)) as HTMLElement[];
                    }
                    if (els.length == 0) {
                        return;
                    }

                    const el = els[0];

                    el.classList.add('errorField');

                    const msg = document.createElement('span');
                    msg.classList.add('validationMessage');
                    msg.dataset['name'] = f.name;
                    msg.textContent = t('validation.' + f.messages[0]);

                    el.parentNode.insertBefore(msg, el.nextSibling);

                    const removeFn = () => {
                        el.classList.remove('errorField');
                        msg.remove();
                    };

                    el.addEventListener('keypress', removeFn);

                    if (el.classList.contains('invisible')) {
                        el.addEventListener('click', removeFn);
                        el.addEventListener('change', removeFn);
                    }
                });
            }}>
            <>
                <RenderForm />
                <RecognizeSuggestionsForm />
            </>
        </Formik>
    </>;
};