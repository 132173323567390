import React from 'react';
import s from './style.module.scss';
import { PageHeader } from '@root/components';
import { useTranslation } from 'react-i18next';

const Header: React.FC = () => {
    const { t } = useTranslation();

    return(
        <div className={s.container}>
            <PageHeader title={t('request.header')} additionalInfo={t('request.subHeader')}/>
        </div>
    );
}

export default Header;